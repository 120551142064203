import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { StaticImage } from 'gatsby-plugin-image'
import { Global } from '@emotion/react'
import { ToastContainer } from 'react-toastify'
import { Helmet } from 'react-helmet'
import { useQueryParam, BooleanParam } from 'use-query-params'

import { useAuth0 } from 'utils/react-auth0-spa'
import { getAuth0LockLangCode } from 'utils/auth0LockLangCode'
import mq from 'components/styleComponents/breakpoints'
import Loading from 'components/General/Loading'
import PurchaseCourseList from 'components/CoursesPage/PurchaseCourseList'
import GlobalStyles from 'components/styleComponents/GlobalStyles'

const UNIMINUTO_FRIENDS_AND_FAMILY_PAGE = '/uniminuto/'

const BACKGROUND_COLOR = '#48b0ca'
const UniminutoCoursesContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${BACKGROUND_COLOR};
  padding-bottom: 2rem;
`

const UniminutoLayoutContainer = styled.div`
  background-color: ${BACKGROUND_COLOR};
  width: 100%;
  height: 100vh;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto;
  box-sizing: border-box;
  font-size: 62.5%;
  margin: 0;
  padding: 0;
`

const CoursesHeader = styled.h1`
  font-size: 5rem;
  color: #ffffff;
  text-align: center;

  ${mq['sm']} {
    font-size: 3.2rem;
  }

  ${mq['xsm']} {
    font-size: 2.8rem;
  }
`

const Index = () => {
  const { isAuthenticated, auth0Loading, loginWithRedirect } = useAuth0()
  const { t, i18n, ready } = useTranslation('uniminutoFriendsAndFamilyPage')
  const [registeredQueryParam] = useQueryParam('registered', BooleanParam)

  const isRegisteredQueryParamValid =
    registeredQueryParam && registeredQueryParam === true

  useEffect(() => {
    if (!auth0Loading && !isAuthenticated) {
      loginWithRedirect({
        ui_locales: getAuth0LockLangCode(i18n.language),
        redirect_uri:
          process.env.GATSBY_WEBSITE_URL + UNIMINUTO_FRIENDS_AND_FAMILY_PAGE,
        mode: 'signUp',
      })
    }
  }, [auth0Loading, isAuthenticated, loginWithRedirect, i18n.language])

  const [
    numberOfGoogleFormFrameLoads,
    setNumberOfGoogleFormFrameLoads,
  ] = useState(0)
  const REDIRECT_PAGE_URI = '/courses/?category=general&option=complete'
  useEffect(() => {
    if (numberOfGoogleFormFrameLoads > 1 && window !== 'undefined') {
      window.location.assign(process.env.GATSBY_WEBSITE_URL + REDIRECT_PAGE_URI)
    }
  }, [numberOfGoogleFormFrameLoads])

  return (
    <>
      <ToastContainer
        limit={1}
        position="bottom-left"
        autoClose={8000}
        hideProgressBar={true}
      />
      <Helmet>
        <title>{t('pageTitle')}</title>
      </Helmet>
      <Global styles={GlobalStyles} />
      <main>
        <UniminutoLayoutContainer>
          {(auth0Loading || !isAuthenticated) && <Loading />}
          {!auth0Loading && isAuthenticated && ready && (
            <>
              <StaticImage
                src="../../images/png/uniminutoFWLogo.png"
                loading="eager"
                alt="Uniminuto and FW logos"
              />
              {!isRegisteredQueryParamValid && (
                <iframe
                  src="https://docs.google.com/forms/d/e/1FAIpQLSezhsHt37noKJpGBg8qZk4pyDASL9g6zhEDCrDb5fc-drPaZQ/viewform?embedded=true"
                  title="Uniminuto Friends and Family Google Form"
                  width="100%"
                  height="80%"
                  frameBorder="0"
                  marginHeight="0"
                  marginWidth="0"
                  onLoad={() => {
                    setNumberOfGoogleFormFrameLoads(
                      numberOfGoogleFormFrameLoads + 1,
                    )
                  }}
                >
                  Loading…
                </iframe>
              )}
              {isRegisteredQueryParamValid && (
                <>
                  <CoursesHeader>
                    {t('registerForDiscountedCourse')}
                  </CoursesHeader>
                  <UniminutoCoursesContainer>
                    <PurchaseCourseList uniminutoCourses={true} />
                  </UniminutoCoursesContainer>
                </>
              )}
            </>
          )}
        </UniminutoLayoutContainer>
      </main>
    </>
  )
}

export default Index
