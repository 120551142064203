import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useQueryParam, StringParam } from 'use-query-params'

import { GET_COURSES, GET_ENROLLED_COURSES } from 'utils/graphql'
import CourseCard from 'components/CoursesPage/CourseCard'
import {
  SectionContainer,
  NoCoursesHeading,
} from 'components/styleComponents/CourseCardComponents'
import LottieShape from 'components/General/LottieShape'
import LoadingAnimation from 'components/General/Loading/LoadingAnimation'
import { useAuth0 } from 'utils/react-auth0-spa'
import { getAuth0LockLangCode } from 'utils/auth0LockLangCode'
import { trackClickEvent } from 'utils/analytics'
import SubscriptionContext from 'context/subscription/SubscriptionContext'

const UNIMINUTO_COURSES_IDS = (process.env.GATSBY_UNIMINUTO_COURSES ?? '')
  .split(',')
  .filter((course) => course !== '')
const COURSES_PATH = '/courses/'
const SELECTED_PRODUCT_ID_QUERY_PARAM_PATH = '?selectedProductId='

const PurchaseCourseList = ({ uniminutoCourses }) => {
  const { auth0Loading, isAuthenticated, loginWithRedirect } = useAuth0()
  const {
    loading: getCoursesLoading,
    error: getCoursesError,
    data: getCoursesData,
  } = useQuery(GET_COURSES)

  const {
    loading: getEnrolledCoursesLoading,
    error: getEnrolledCoursesError,
    data: getEnrolledCoursesData,
  } = useQuery(GET_ENROLLED_COURSES)
  const { t, i18n } = useTranslation(['general', 'courseCardComponent'])

  const [selectedProductId] = useQueryParam('selectedProductId', StringParam)

  useEffect(() => {
    if (
      !auth0Loading &&
      isAuthenticated &&
      selectedProductId &&
      selectedProductId !== ''
    ) {
      redirectToStripeCheckoutSession(selectedProductId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProductId, auth0Loading, isAuthenticated])

  const {
    subscriptionStateLoading,
    createStripeCheckoutSession,
    getProductPricing,
  } = useContext(SubscriptionContext)

  const redirectToStripeCheckoutSession = async (productId) => {
    if (!auth0Loading && !isAuthenticated) {
      loginWithRedirect({
        ui_locales: getAuth0LockLangCode(i18n.language),
        redirect_uri:
          process.env.GATSBY_WEBSITE_URL +
          COURSES_PATH +
          SELECTED_PRODUCT_ID_QUERY_PARAM_PATH +
          productId,
      })
    }

    trackClickEvent('registerButton', productId)

    const prices = await getProductPricing(productId, [
      uniminutoCourses ? 'cop' : 'usd',
    ])
    const selectedPriceId = prices ? prices[0]?.id ?? '' : ''

    if (selectedPriceId !== '') {
      await createStripeCheckoutSession(productId, selectedPriceId)
    } else {
      toast.error(t('error'))
    }
  }

  useEffect(() => {
    if (
      (getCoursesError || (getEnrolledCoursesError && isAuthenticated)) &&
      !auth0Loading
    ) {
      toast.error(t('error'))
    }
  }, [
    getCoursesError,
    getEnrolledCoursesError,
    t,
    auth0Loading,
    isAuthenticated,
  ])

  return getCoursesLoading || getEnrolledCoursesLoading || auth0Loading ? (
    <LottieShape animationData={LoadingAnimation} />
  ) : getCoursesData?.getCourses?.courses?.length > 0 ? (
    <SectionContainer>
      {getCoursesData?.getCourses?.courses.map((course) => {
        const isUniminutoCourse = UNIMINUTO_COURSES_IDS.includes(course.id)

        if (uniminutoCourses && isUniminutoCourse) {
          return (
            <CourseCard
              course={course}
              key={course.id}
              buttonAction={redirectToStripeCheckoutSession}
              buttonLoading={subscriptionStateLoading}
              isPurchase
              isOwned={
                getEnrolledCoursesData?.getEnrolledCourses?.courses.some(
                  (ownedCourse) => ownedCourse.id === course.id,
                ) ?? false
              }
            />
          )
        } else if (!uniminutoCourses && !isUniminutoCourse) {
          return (
            <CourseCard
              course={course}
              key={course.id}
              buttonAction={redirectToStripeCheckoutSession}
              buttonLoading={subscriptionStateLoading}
              isPurchase
              isOwned={
                getEnrolledCoursesData?.getEnrolledCourses?.courses.some(
                  (ownedCourse) => ownedCourse.id === course.id,
                ) ?? false
              }
            />
          )
        }

        return null
      })}
    </SectionContainer>
  ) : (
    <NoCoursesHeading>
      {t('courseCardComponent:noCoursesAvailable')}
    </NoCoursesHeading>
  )
}

PurchaseCourseList.defaultProps = {
  uniminutoCourses: false,
}

PurchaseCourseList.propTypes = {
  uniminutoCourses: PropTypes.bool,
}

export default PurchaseCourseList
