import styled from '@emotion/styled'
import mq from './breakpoints'

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  align-items: center;
  margin-bottom: 8rem;

  ${mq['md']} {
  }
`

export const CardContainer = styled.div`
  display: flex;
  background-color: rgba(255, 255, 255, 0.75);
  width: 992px;
  font-size: 2rem;
  margin-top: 1rem;
  justify-content: space-between;

  border-radius: 20px;
  box-shadow: 0 4px 2px -2px #000000;

  ${mq['lg']} {
    width: 90%;
  }

  ${mq['md']} {
    flex-direction: column;
    justify-content: center;
  }

  ${mq['sm']} {
    font-size: 1.5rem;
  }
`

export const LeftContainer = styled.div`
  display: flex;
  width: 70%;

  ${mq['md']} {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  ${mq['sm']} {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
`

export const Title = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  padding-bottom: 1rem;
`

export const ImageContainer = styled.div`
  width: 10em;
  padding: 1rem;
  text-align: center;

  ${mq['md']} {
    width: 6em;
  }
`

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  ${mq['md']} {
    justify-content: center;
    align-items: center;
  }
`

export const CourseTitle = styled.div`
  display: flex;
`

export const CourseDescription = styled.div`
  display: flex;
  margin-top: 2rem;
  margin-right: 3rem;

  ${mq['md']} {
    margin: 1rem;
  }
`

export const CourseTime = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  padding-bottom: 2rem;
`

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 6rem;
  padding-right: 3rem;

  ${mq['md']} {
    padding-right: 0rem;
  }
`

export const Button = styled.button`
  background: #e61d5f;
  color: #ffffff;
  font-size: 2rem;
  border: none;
  padding: 1rem 2rem;
  cursor: pointer;
  margin-top: 1rem;

  ${mq['md']} {
    margin-left: 2rem;
    margin-right: 2rem;
  }
`

export const CourseCardButtonDateContainer = styled.div`
  margin-top: 4rem;
  text-align: center;

  ${mq['md']} {
    padding-bottom: 2rem;
  }

  ${mq['sm']} {
    padding-bottom: 2rem;
  }
`

export const NoCoursesHeading = styled.h1`
  color: #ffffff;
`

export const RegisterEventButton = styled.button`
  background: #e61d5f;
  color: #ffffff;
  font-size: 2rem;
  border: none;
  padding: 1rem 2rem;
  cursor: pointer;
  margin-top: 1rem;

  ${mq['md']} {
    margin-left: 2rem;
    margin-right: 2rem;
  }
`
export const SubscriptionRightContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const SubscriptionEventTitle = styled.div`
  margin-top: auto;
  padding-bottom: 1rem;
  display: flex;
`

export const SubscriptionEventTime = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: auto;

  ${mq['md']} {
    padding-bottom: 2rem;
  }
`

export const UserEventAllowanceMessage = styled.div`
  margin: 2rem 0;
  font-size: 3rem;
  text-align: center;
  color: #ffffff;

  ${mq['md']} {
    font-size: 2.5rem;
  }

  ${mq['sm']} {
    font-size: 1.8rem;
  }
`

export const AttendButton = styled.button`
  background: #e61d5f;
  color: #ffffff;
  font-size: 2rem;
  border: none;
  padding: 1rem 2.5rem;
  cursor: pointer;
  margin: auto 0;
  margin-right: 3rem;

  ${mq['md']} {
    margin: 1rem auto;
  }

  :disabled {
    background-color: #a9a9a9;
    cursor: default;
  }
`
