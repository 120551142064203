import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const CourseCardSessionSelect = ({ sessions, onChange, defaultValue }) => {
  const { t } = useTranslation('courseCardComponent')

  const sessionOptions = []
  for (const session of sessions) {
    const sessionOptionLabel = `${session.name} (${moment
      .unix(session.scheduledAt)
      .local()
      .format(t('dateFormat'))})`

    sessionOptions.push({ value: session.id, label: sessionOptionLabel })
  }

  const defaultOption = sessionOptions.find(
    (sessionOption) => sessionOption.value === defaultValue,
  )

  return (
    <Select
      options={sessionOptions}
      onChange={(e) => onChange(e.value)}
      defaultValue={defaultOption}
    />
  )
}

CourseCardSessionSelect.propTypes = {
  sessions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      scheduledAt: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
}

export default CourseCardSessionSelect
