import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { GatsbyImage } from 'gatsby-plugin-image'
import moment from 'moment'

import LottieShape from 'components/General/LottieShape'
import LoadingAnimation from 'components/General/Loading/LoadingAnimation'
import {
  CardContainer,
  ImageContainer,
  ColumnContainer,
  CourseTitle,
  CourseDescription,
  CourseTime,
  RightContainer,
  LeftContainer,
  Button,
  CourseCardButtonDateContainer,
} from 'components/styleComponents/CourseCardComponents'
import CourseCardSessionSelect from 'components/CoursesPage/CourseCardSessionSelect'

const getImages = graphql`
  {
    alejaBriles: file(relativePath: { eq: "png/teacherAlejaBriles.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED)
      }
    }
    kathiaPerez: file(relativePath: { eq: "png/teacherKathiaPerez.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED)
      }
    }
    sofiaMunoz: file(relativePath: { eq: "png/teacherSofiaMunoz.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED)
      }
    }
    camilaBrosio: file(relativePath: { eq: "png/teacherCamilaBrosio.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED)
      }
    }
    ericaLovell: file(relativePath: { eq: "png/teacherEricaLovell.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED)
      }
    }
    hannahGunther: file(relativePath: { eq: "png/teacherHannahGunther.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED)
      }
    }
    mayelinJimenez: file(
      relativePath: { eq: "png/teacherMayelinJimenez.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED)
      }
    }
    camilaCastrillon: file(
      relativePath: { eq: "png/teacherCamilaCastrillon.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED)
      }
    }
  }
`

const CourseCard = ({
  course,
  instructor,
  buttonAction,
  buttonLoading,
  isPurchase,
  isOwned,
  setSelectedSessionId,
}) => {
  const imageData = useStaticQuery(getImages)
  const { t, i18n } = useTranslation('courseCardComponent')

  const TEACHER_MAP = {
    'auth0|60ca7b22d29d3b006ab0c5a0': {
      name: 'Kathia Perez',
      image: 'kathiaPerez',
    },
    'auth0|60481946b450f7006802de25': {
      name: 'Sofia Munoz',
      image: 'sofiaMunoz',
    },
    'auth0|60ca7640447e37006978fcdc': {
      name: 'Camila Brosio',
      image: 'camilaBrosio',
    },
    'auth0|60da1b3448fecd0069911a96': {
      name: 'Erica Lovell',
      image: 'ericaLovell',
    },
    'auth0|60d6179edc4f2300712b8f61': {
      name: 'Hannah Gunther',
      image: 'hannahGunther',
    },
    'auth0|60ca7ed9507eae006fb8c157': {
      name: 'Mayelin Jimenez',
      image: 'mayelinJimenez',
    },
    'auth0|6046b3d138732f006aa8ee25': {
      name: 'Camila Castrillon',
      image: 'camilaCastrillon',
    },
    unknown: {
      name: 'unknown teacher',
      image: 'alejaBriles',
    },
  }

  const calculateSchedule = useCallback(
    (sessions) => {
      // Insertion order will be used for displaying.
      //  e.g. if a class is TH but the first session is on Thursday (H), this would end up being [Thursday, Tuesday].
      // If this becomes an issue, we can address this then.
      const daysSet = sessions.reduce((daySet, session) => {
        daySet.add(
          moment.unix(session.scheduledAt).locale(i18n.language).format('dddd'),
        )
        return daySet
      }, new Set())
      const days = [...daysSet]
      const dayString = t('dayRange', {
        first: days[0],
        second: days[days.length - 1],
      })
      const firstSessionMoment = moment
        .unix(sessions[0]?.scheduledAt)
        .locale(i18n.language)

      const timeString = t('timeRange', {
        beginning: firstSessionMoment.format(t('timeFormat')),
        end: firstSessionMoment.add(1, 'hour').format(t('timeFormat')),
        timezone: t(
          `timezones.${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
        ).replace('timezones.', ''),
      })

      return {
        days: dayString,
        time: timeString,
      }
    },
    [t, i18n],
  )

  // TODO: Make metadata values data driven
  const courseInfo = {
    ...course,
    metadata: {
      teacher: TEACHER_MAP[course.instructorId] ?? TEACHER_MAP.unknown,
      schedule: calculateSchedule(course.sessions),
    },
  }

  const nextSessionDate = moment
    .unix(
      courseInfo.sessions[
        courseInfo.sessions.indexOf(courseInfo.activeSessionId) + 1
      ]?.scheduledAt,
    )
    .locale(i18n.language)
    .format(t('dateFormat'))
  const registrationDeadline = moment
    .unix(courseInfo.sessions[0]?.scheduledAt)
    .locale(i18n.language)
    .subtract(1, 'days')
    .format(t('dateFormat'))

  const duration = `${courseInfo.sessions.length} ${t('durationUnit')}`

  const buttonText = instructor
    ? t('teachSession')
    : isPurchase && isOwned
    ? t('coursePurchased')
    : isPurchase && !isOwned
    ? t('registerNow')
    : t('attendSession')

  return (
    <CardContainer>
      <LeftContainer>
        <ColumnContainer>
          <ImageContainer>
            <GatsbyImage
              image={
                imageData[courseInfo.metadata.teacher.image].childImageSharp
                  .gatsbyImageData
              }
              alt="Teacher image"
            />
            <p>{courseInfo.metadata.teacher.name}</p>
          </ImageContainer>
        </ColumnContainer>
        <ColumnContainer>
          <CourseTitle>
            {courseInfo.name} - {duration}
          </CourseTitle>
          <CourseDescription>{courseInfo.description}</CourseDescription>
          <CourseTime>
            <p>{courseInfo.metadata.schedule.days}</p>
            <p>{courseInfo.metadata.schedule.time}</p>
          </CourseTime>
        </ColumnContainer>
      </LeftContainer>
      <RightContainer>
        {instructor && (
          <CourseCardSessionSelect
            sessions={courseInfo.sessions}
            onChange={setSelectedSessionId}
            defaultValue={courseInfo.activeSessionId}
          />
        )}
        <Button
          onClick={
            isPurchase
              ? () => buttonAction(courseInfo.productId)
              : () => buttonAction(courseInfo.id)
          }
          disabled={isOwned}
        >
          {buttonLoading ? (
            <LottieShape animationData={LoadingAnimation} size={40} />
          ) : (
            buttonText
          )}
        </Button>
        <CourseCardButtonDateContainer>
          {isPurchase ? (
            <>
              <p>{t('registrationDeadline')}:</p>
              <p>{registrationDeadline}</p>
            </>
          ) : (
            <>
              <p>{t('nextSession')}:</p>
              <p>{nextSessionDate}</p>
            </>
          )}
        </CourseCardButtonDateContainer>
      </RightContainer>
    </CardContainer>
  )
}

CourseCard.defaultProps = {
  instructor: false,
}

CourseCard.propTypes = {
  course: PropTypes.shape({
    id: PropTypes.string,
    instructorId: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    productId: PropTypes.string,
    sessions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        scheduledAt: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
  }),
  instructor: PropTypes.bool,
  isPurchase: PropTypes.bool,
  isOwned: PropTypes.bool,
  buttonAction: PropTypes.func,
  buttonLoading: PropTypes.bool,
  setSelectedSessionId: PropTypes.func,
}

export default CourseCard
